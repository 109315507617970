import * as React from "react"
import "resources/style/style.less"
import TopSectionBG from "components/section/ppob/top.sc"
import TitleSection from "components/section/ppob/title.sc"
import BergabungSection from "components/section/ppob/bergabung.sc"
import ContentSection from "components/section/ppob/content.sc"
import Seo from "../components/seo"
import Layout from "components/layout"

const IndexPage = () => {
  return (
    <>
      <Seo title={"Wispay outlet"} />
      <Layout headerVariant="normal" headerBackground="blue" page="outlet">
        <TopSectionBG />
        <TitleSection />
        <ContentSection />
        <BergabungSection />
      </Layout>
    </>
  )
}

export default IndexPage
