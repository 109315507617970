import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import LeftImageSection from "components/items/image-left-section"
import RightImageSection from "components/items/image-right-section"

import ImagePengaturan from "resources/img/ppob/pengaturan-keuntungan.png"
import ImagePilihan from "resources/img/ppob/banyak-pilihan.png"
import ImageJualan from "resources/img/ppob/jualan-mudah.png"

const ContentSection = () => {
  return (
    <div className="main-content-section">
      <StaticImage
        className="shape-right-content-partner"
        placeholder="none"
        src={"../../../resources/img/shape/round-shape-2.png"}
        alt="shape-right"
      />
      <LeftImageSection
        img={ImagePengaturan}
        title="Pengaturan Keuntungan yang bisa di atur sesuai kebutuhan."
        desc="Anda bisa mengatur nominal keuntungan sesuai dengan
        keinginan & kebutuhan anda, semua bisa di lakukan dalam satu
        aplikasi."
      />
      <RightImageSection
        img={ImageJualan}
        title="Proses berjualan yang mudah dan cepat."
        desc="Berjualan Pulsa dan berbagai produk tagihan lainnya tanpa
        harus instal berbagai aplikasi, semua bisa dilakukan dalam
        satu aplikasi wispay."
      />
      <LeftImageSection
        img={ImagePilihan}
        title="Banyak pilihan produk yang bisa di tawarkan kepada customer."
        desc="Kami memiliki banyak produk mulai dari pulsa, tagihan PLN
        hingga voucher game dan masih banyak lainnya."
      />
      <StaticImage
        className="shape-left-content-partner"
        placeholder="none"
        src={"../../../resources/img/shape/round-shape-1.png"}
        alt="shape left"
      />
    </div>
  )
}

export default ContentSection
