import React from "react"
import { Col, Row, Typography } from "antd"

import Google from "resources/img/download/googlePlay.png"
import Appstore from "resources/img/download/appStore.png"
import BackgroundBergabung from "resources/img/ppob/bergabung-dengan-kami.png"

const BergabungSection = () => {
  return (
    <div id="produk">
      <div className="base-container-gabung-ppob">
        <Col xl={20} lg={20} md={20} sm={24} xs={24}>
          <div className="container-ppob">
            <img
              className="shape-bg"
              src={BackgroundBergabung}
              alt="bg bergabung"
            />
            <div className="img-container-download" />
            <div className="linear-container-download" />
            <div className="container-area-image-section">
              <div className="center-middle">
                <Typography.Text className="title-download">
                  Bergabung bersama kami.
                </Typography.Text>
                <Typography.Text className="desc-download">
                  Ayo, segera bergabung menjadi Mitra kami dan nikmati berbagai
                  keunggulan yang kami tawarkan.
                </Typography.Text>
              </div>
              <Row className="area-image-download" gutter={16}>
                <Col justify="center">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.wispayoutlet"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={Google}
                      alt="button play store"
                      className="img-download"
                    />
                  </a>
                </Col>
                <Col justify="center">
                  <img
                    src={Appstore}
                    className="img-download"
                    alt="button app store"
                    style={{ cursor: "default" }}
                  />
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </div>
    </div>
  )
}

export default BergabungSection
